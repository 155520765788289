import React from 'react';
import main from '../../Assets/Styles/main.json';
import { StyleSheet } from 'react-native';
import { Box, Heading, Stack, Text } from 'native-base';
import Trans from './Trans';

const mainStyle = StyleSheet.create(main);

const AvailabilityItem = (props) => {
    return (
        <Box style={[mainStyle.boxItem, { paddingVertical: 0 }]}>
            <Box style={{ flex: 1 }}>
                <Stack
                    direction={'row'}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                    space={2}
                    style={{ marginVertical: 10 }}
                >
                    <Box>
                        <Stack
                            direction={props.small ? 'column' : {
                                md: 'row',
                                base: 'column'
                            }}
                            alignItems={'center'}
                            space={props.small ? 0 : {
                                md: 4,
                                base: 0
                            }}
                        >
                            {/* TODO: uncomment userimage and pass user prop */}
                            {/* <UserImage
                                                style={{height: 27, width: 27}}
                                                user={props.data.user}
                                            /> */}
                            <Heading style={[mainStyle.listDataTitle, { flexGrow: 0, fontSize: 12, fontWeight: 'normal' }]}>
                                {props.availability.employee.user.firstName} {props.availability.employee.user.lastName}
                            </Heading>
                        </Stack>
                    </Box>
                    {/* TODO: Replace true with available or unavailable variable */}
                    <Box style={[props.availability.available ? { backgroundColor: 'green' } : { backgroundColor: 'red' }, { paddingHorizontal: 7, borderRadius: 5 }]}>
                        <Text style={{ color: 'white' }}><Trans>{props.availability.available ? 'Available' : 'Unavailable'}</Trans></Text>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}

export default AvailabilityItem;