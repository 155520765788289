import React from 'react';
import {Linking, StyleSheet} from 'react-native';
import main from '../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import {Box, HStack, Heading, Text, IconButton, useTheme, Button, VStack} from 'native-base';
import { useNavigation } from '@react-navigation/native';
import Config from '../../../Libs/Config';
import GeneralAction from "../../../Actions/GeneralAction";
import Trans from '../../../Views/Components/Trans';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const openDocument = (props) => {
    let url = Config.DOCUMENTS_URL + '/nl/common/sales_invoice/exact/details/' + props;
    Linking.openURL(url);
};

const Badge = (props) => {
    return (
        <Box
            backgroundColor={`${props.colorScheme}.100`}
            borderRadius={'xl'}
            my={1}
            mx={1}
            px={2}
            py={1}
            flexShrink={1}
        >
            <Text color={`${props.colorScheme}.600`} fontSize={10} >{props.text}</Text>
        </Box>
    );
};

const InvoiceItem = (props) => {
    const navigation = useNavigation();
    const { colors } = useTheme();

    return (
        <VStack
                style={{borderWidth: 1, padding: 7, paddingBottom: 3, borderColor: '#ddd', borderRadius: 10}}
                space={2}
                >
            <HStack
                space={2}
                alignItems={'center'}
                flexGrow={1}
            >
                <Button 
                    display={{
                        base: 'none',
                        sm: 'flex'
                    }}
                    variant={'subtle'}
                    leftIcon={props.data.documentUrl ? <Icon name="download" size="sm" /> : <Icon name="times" size="sm" />}
                    colorScheme={props.data.documentUrl ? 'primary' : 'gray'}
                    borderRadius={'full'}
                    onPress={() => { openDocument(props.data.id) }}
                    style={{minWidth: 110}}
                    >
                        {props.data.documentUrl ?
                        <Text style={{fontSize: 12}}>
                            Download
                        </Text>
                        :
                        <Text style={{fontSize: 12}}>
                            No file
                        </Text>
                        }
                </Button>
                <VStack>
                    <HStack
                        alignItems={'center'}
                    >
                        <Text>
                            {props.data.description}
                        </Text>
                        {/* <Text>
                            {props.data.invoiceStatus &&
                                <Badge text={props.data.invoiceStatus} colorScheme={'yellow'} />
                            }
                            
                        </Text> */}
                    </HStack>
                    <HStack
                        display={{
                            base: 'flex',
                            sm: 'none'
                        }}
                    >
                        {props.data.amount !=0 &&
                            <Badge text={'Incl. BTW: ' + GeneralAction.formatPrice(props.data.amount)} colorScheme={'light'} />
                        }

                        {props.data.dueDate &&
                            <Badge text={'Due date: ' + GeneralAction.formatDate(props.data.dueDate)} colorScheme={'light'} />
                        }
                    </HStack>
                    {/* {props.data.invoiceDescription &&
                        <Text fontSize={12}>{props.data.invoiceDescription}</Text>
                    } */}
                </VStack>
                <Box
                    display={{
                        base: 'none',
                        sm: 'flex'
                    }}
                    flexGrow={1}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                >
                    {props.data.amount !=0 &&
                        <VStack style={mainStyle.timeLabel}>
                            <Text fontSize={12}><Trans>Incl. BTW</Trans></Text>
                            <Text fontSize={12}>{GeneralAction.formatPrice(props.data.amount)}</Text>
                        </VStack>
                    }

                    {props.data.dueDate &&
                        <VStack style={[mainStyle.timeLabel, {marginLeft: 5}]}>
                            <Text fontSize={12}><Trans>Due date</Trans></Text>
                            <Text fontSize={12}>{GeneralAction.formatDate(props.data.dueDate)}</Text>
                        </VStack>
                    }
                </Box>
            </HStack>
            <Button 
                display={{
                    base: 'flex',
                    sm: 'none'
                }}
                variant={'subtle'}
                leftIcon={props.data.documentUrl ? <Icon name="download" size="sm" /> : <Icon name="times" size="sm" />}
                colorScheme={props.data.documentUrl ? 'primary' : 'gray'}
                borderRadius={'full'}
                onPress={() => { openDocument(props.data.id) }}
                style={{minWidth: 110, marginBottom: 3}}
                >
                    {props.data.documentUrl ?
                    <Text style={{fontSize: 12}}>
                        <Trans>Download</Trans>
                    </Text>
                    :
                    <Text style={{fontSize: 12}}>
                        <Trans>No file</Trans>
                    </Text>
                    }
            </Button>
        </VStack>
    );
};

export default InvoiceItem;
