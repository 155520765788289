import React, { useRef, useState, useEffect } from 'react';
import { Box, Skeleton, Text, VStack } from 'native-base';
import main from '../../Assets/Styles/main.json';
import { StyleSheet } from 'react-native';
import APIAction from '../../Actions/APIAction';
import GeneralAction from '../../Actions/GeneralAction';
import AvailabilityItem from './AvailabilityItem';


const mainStyle = StyleSheet.create(main);

const Availabilities = (props) => {

    const
        [loading, setLoading] = useState(true),
        employees = useRef([]),
        [items, setItems] = useState([])
        ;

    const loadData = useEffect(() => {
        let init = async () => {

            //set loading
            setItems([]);
            setLoading(true);

            let startDate = new Date(props.startDate);
            startDate.setHours(0, 0, 0, 0);
            let endDate = new Date(props.endDate);
            endDate.setHours(23, 59, 59, 999);

            //get availabilities
            let availabilities = await APIAction.request({
                url: '/api/employee_availabilities',
                method: 'GET',
                params: {
                    dateStart: props.startDate,
                    dateEnd: props.endDate
                }
            });
            availabilities = availabilities['hydra:member'];

            //add employee, only do once per employee
            for (let [index, availability] of availabilities.entries()) {
                if (!(availability.employee in Object.keys(employees.current))) {
                    let employee = await APIAction.request({
                        url: availability.employee,
                        method: 'GET'
                    });
                    employees.current[availability.employee] = employee;
                }

                availabilities[index].employee = employees.current[availability.employee];
            }

            //sort availabilities by date
            availabilities.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
            });

            let sortedAvailabilities = [];
            for (let availability of availabilities) {
                let date = new Date(availability.date);
                date.setHours(0, 0, 0, 0);
                let dateKey = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
                if (sortedAvailabilities[dateKey] === undefined) {
                    sortedAvailabilities[dateKey] = [];
                }
                sortedAvailabilities[dateKey].push(availability);
            }

            let newItems = [];
            for (let [date, availabilities] of Object.entries(sortedAvailabilities)) {

                let dateObj = new Date(date);
                let dateString = await GeneralAction.formatLocal(dateObj, {
                    day: 'numeric',
                    month: 'long',
                });

                //sort availabilities by employee
                availabilities.sort((a, b) => {
                    return a.employee.user.lastName.localeCompare(b.employee.user.lastName);
                });

                newItems.push(
                    <Box style={{ paddingTop: 15 }}>
                        <VStack style={[mainStyle.boxItemVertical, { paddingVertical: 10 }]}>
                            <Text style={[mainStyle.mediumTitle, { marginBottom: 10 }]}>{dateString}</Text>
                            {availabilities.map((availability) => {
                                return (
                                    <AvailabilityItem availability={availability} />
                                );
                            })}
                        </VStack>
                    </Box>
                );
            }

            setLoading(false);
            setItems(newItems);
        };
        init();
    }, [props.startDate, props.endDate, setItems]);

    return (
        <>
            {items}
            <Skeleton
                marginTop={15}
                isLoaded={!loading}
                height={20}
            />
        </>
    );
};

export default Availabilities;
